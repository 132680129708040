.bg-primary {
  background-color: $primary !important;
}

.text-primary-hover {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $primary !important;
  }
}

.bg-gray {
  background-color: #ededed;
}
.bg-light-gray {
  background-color: #f7f7f7;
}
.bg-secondary {
  background-color: $secondary !important;
}

.text-primary {
  color: $primary !important;
}
.text-primary-dark {
  color: $primary-dark !important;
}

.f-500 {
  font-weight: 500!important;
}

.text-muted {
  color: $text-muted !important;
}
.text-success {
  color: $green !important;
}

.text-danger {
  color: $red !important;
}

.bg-danger {
  background-color: $bg-table-row-error !important;
}

.w-10 {
  width: 10%;
}
.w-16 {
  width: 16%;
}
.w-18 {
  width: 18%;
}
.w-25 {
  width: 25%;
}
.w-23 {
  width: 23%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33.33%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.h-200-px {
  height: 200px;
}

.f-10 {
  font-size: 10px;
}
.f-11{
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px !important;
}
.f-13 {
  font-size: 13px !important;
}

.f-18 {
  font-size: 18px;
}

.pointer {
  cursor: pointer;
}

.text-transform-inherit {
  text-transform: inherit !important;
}
