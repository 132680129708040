.form-submitted{
  .form-control {
    &.ng-invalid {
      @include ng-invalid();
    }
  }
  .form-control-tel-input.ng-invalid{
    .form-control-tel-input-field{
      @include ng-invalid();
    }
  }
  .multi-select.error{
    .multi-select-input-dropdown{
      @include ng-invalid();
      &:after{
        right: 30px;
      }
    }
  }
  .pwc-select.error{
    .select-input-dropdown{
      @include ng-invalid();
      &:after{
        right: 30px;
      }
    }
  }
  .pwc-date-picker{
    input{
      &.ng-invalid {
        background-image: none !important;
        /*@include ng-invalid();
        background-size: calc(.75em + 4.375rem) calc(.75em + .375rem) !important;*/
      }
    }
  }
}

.form-control {
  &.ng-touched.ng-invalid {
   @include ng-invalid();
  }
}

.form-control-tel-input.ng-touched.ng-invalid {
  .form-control-tel-input-field{
    @include ng-invalid();
  }
}

textarea {
  .form-control {
    &.ng-touched.ng-invalid {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
    }
  }
}

.custom-select {
  &.ng-touched.ng-invalid {
    border-color: $required-sign-color;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    &:focus {
      border-color: $required-sign-color;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.form-check-input.ng-invalid ~ .form-check-label {
  color: $required-sign-color;
}

.form-check-input.ng-invalid ~ .invalid-feedback, .form-check-input.ng-invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.ng-invalid ~ .custom-control-label {
  color: $required-sign-color;
}

.custom-control-input.ng-invalid ~ .custom-control-label::before {
  border-color: $required-sign-color;
}

.custom-control-input.ng-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.custom-control-input.ng-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input.ng-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $required-sign-color;
}

.custom-file-input.ng-invalid ~ .custom-file-label {
  border-color: $required-sign-color;
}

.custom-file-input.ng-invalid:focus ~ .custom-file-label {
  border-color: $required-sign-color;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-label {
  min-height: 48px;
}

.ml-input-field {
  margin-left: 0.75rem;
}

.mr-input-field {
  margin-right: 0.75rem;
}

.mx-input-field {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
