.primary-heading {
  font-size: 55px;
  font-weight: bold;
  color: #bb9875;
  text-transform: uppercase;
  @media(max-width:600px){
    font-size: 25px;

  }
}

.secondary-heading {
  font-size: 16px;
  font-weight: 500;
}
.paragraph {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.7;
  @media(max-width:600px){
    font-size: 14px;
  }
}
.sub-heading {
  font-size: 35px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  @media(max-width:600px){
    font-size: 20px;

  }
}

a {
  transition: color 0.3s ease-in-out;
  &:hover {
    text-decoration: none;
    color: $primary;
  }
}

i.icon-center {
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
