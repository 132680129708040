*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  ///*Now convert all px value into rem dependent on root font-size  */
  ///*font-size: 10px; */  /*Set root font-size is 10px because  calculate rem is easy 10px == 1rem*/
  ///*default root font size is 16px*/
  //font-size: 62.5%; /* (10/16) == 0.625 => 0.625*100 == 62.5%*/
  //
  //@include responsive(small-desktop){
  //  font-size: 56.25%;         // 1 rem = 9px, 9/16 => 0.5625*100 = 56.25%
  //}
  //
  //@include responsive(tab-port){  // width < 600
  //  font-size: 50%;         // 1 rem = 8px, 8/16 => 0.5*100 = 50%
  //}
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: $primary-font-family !important;
  font-weight: normal;
  font-size: $font-size;
  color: $text-color;
  // background-color: $gray;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $primary $border-color;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: $primary;
}

*::-webkit-scrollbar-thumb {
  background-color: $border-color;
  border-radius: 20px;
  border: 3px solid $primary;
}

a,
button {
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    color: inherit;
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

a {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $primary;
  }
}

ul {
  list-style: none;
  margin: 0;
  // li {
  //   // display: inline-block;
  // }
}

i {
  font-size: 18px;
}

.modal {
  .modal-dialog {
    min-width: 45vw;
    min-height: 45vh;
    .modal-body {
      max-height: 45vh;
      @extend .fancy-scroll;
    }
    .modal-header {
      border: none;
      min-height: 60px;
    }
    .modal-body {
      overflow-y: auto;
    }
  }
  &.popup-40 {
    .modal-dialog {
      min-width: 40vw;
      max-width: 40vw;
      .modal-body {
        max-height: 40vh;
      }
    }
  }
  &.popup-50 {
    .modal-dialog {
      min-width: 50vw;
      max-width: 50vw;
      .modal-body {
        max-height: 50vh;
      }
    }
  }
  &.popup-55 {
    .modal-dialog {
      min-width: 55vw;
      max-width: 55vw;
      .modal-body {
        max-height: 55vh;
      }
    }
  }
  &.popup-60 {
    .modal-dialog {
      min-width: 60vw;
      max-width: 60vw;
      .modal-body {
        max-height: 60vh;
      }
    }
  }
  &.popup-80 {
    .modal-dialog {
      min-width: 80vw;
      max-width: 80vw;
      .modal-body {
        max-height: 80vh;
      }
    }
  }
  &.popup-90 {
    .modal-dialog {
      min-width: 90vw;
      max-width: 90vw;
      .modal-body {
        max-height: 90vh;
      }
    }
  }
  &.popup-40vh {
    min-height: 40vh;
    .modal-body {
      max-height: 40vh;
    }
  }
  &.popup-50vh {
    min-height: 50vh;
    .modal-body {
      max-height: 50vh;
    }
  }
  &.popup-60vh {
    min-height: 60vh;
    .modal-body {
      max-height: 60vh;
    }
  }
  &.popup-80vh {
    min-height: 80vh;
    .modal-body {
      max-height: 80vh;
    }
  }
  &.popup-90vh {
    min-height: 90vh;
    .modal-body {
      max-height: 90vh;
    }
  }
}

*,
.fancy-scroll {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #9d9d9d;
    }
  }
  &.thick-height {
    &::-webkit-scrollbar-track {
      background-color: #d04d27;
    }
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
}
.fancy-scroll {
  overflow-y: auto;
  &.scroll-x-only {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.cursor-pointer {
  cursor: pointer;
}
