@mixin responsive($breakpoint){

  @if $breakpoint == phone{
    @media only screen and (max-width: 37.5em) {@content}   // 600/16 == 37.5em == 600px   called xs
  }
  @if $breakpoint == tab-port{
    @media only screen and (max-width: 56.25em) {@content}  // 900/16 == 56.25em == 900px called sm
  }
  @if $breakpoint == tab-land{
    @media only screen and (max-width: 75em) {@content}    // 1200/16 == 75em == 1200px called md
  }

  @if $breakpoint == small-desktop{
    @media only screen and (max-width: 87.5em) {@content}    // 1400/16 == 87.5em == 1400px
  }

  @if $breakpoint == big-desktop{
    @media only screen and (min-width: 112.5em) {@content} // 1800/16 == 112.5em == 1800px
  }
}


@mixin badge-color($color) {
  color: $color;
  border: solid 0.5px rgba($color, 0.1);
  background-color: rgba($color, 0.1);
  &:before{
    background-color: $color;
  }
}
@mixin badge-dropdown-color($color) {
  color: $color;
  &:hover{
    border: solid 0.5px rgba($color, 0.1);
    background-color: rgba($color, 0.1);
  }
}

@mixin ping-color($color) {
  width: 20px;
  height: 20px;
  background-color: $color;
  border: 1px solid $color;
  border-radius: 50%;
}

@mixin btn-color($color) {
  &, &:active{
    background-color: $color !important;
    border-color: $color !important;
    color: #fff;
    &:hover{
      box-shadow: none;
      color: $color;
      background-color: #fff !important;
    }
  }
}

@mixin btn-outline-color($color) {
  &, &:active{
    border-color: $color !important;
    color: $color;
    background-color: #fff !important;
    &:hover{
      background-color: $color !important;
      color: #fff;
      box-shadow: none;

    }
  }
}

@mixin ng-invalid() {
  border-color: $required-sign-color;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  &:focus {
    border-color: $required-sign-color;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}


@mixin icon($code){
  content: $code;
  font-family: "pwc" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 18px;
  font-weight: 700;
}
