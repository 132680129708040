.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h3,
h2,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.theme-switcher {
  position: absolute;
  top: 8%;
  right: 0;
  background-color: #ffffff57;
  border: 0;
  transition: ease-in-out 0.4s;
  transform: translateX(50%);
  padding: 10px;
  height: auto;
  z-index: 9999;
  &:hover {
    color: black !important;
    transform: translateX(0%);
  }
  .sun {
    transition: ease-in-out 0.3s;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .moon {
    transition: ease-in-out 0.3s;
    &:hover {
      color: beige;
    }
  }
}

.line{
  background-color: #ff4742;
  padding: 2px 0;
  width: 100%;
  flex: 1;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}